import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_j = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - J'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>J</h2>
     <LatQuotesIntro />
    
     <p><b>Judex est lex loquens</b> - A judge is the law speaking.</p>
<p><b>Judex non potest esse testis in propira causa</b> - A judge cannot be witness in his own cause.</p>
<p><b>Judex non potest injuriam sibi datam punire</b> - A judge cannon punish a wrong done to himself.</p>
<p><b>Judex non reddit plus quam quod petens ipse requirit</b> - A judge does not give more than the plaintiff himself demands.</p>
<p><b>Judiciis posterioribus fides est adhibenda</b> - Faith must be given to later decisions.</p>
<p><b>Judicis est judicare secundum allegata et probata</b> - It is the duty of a judge to decide according to the allegations and the proofs.</p>
<p><b>Judicium non debet esse illusorium, suum effectum habere debet</b> - A judgment ought not to be illusory; it ought to have its proper effect.</p>
<p><b>Juduces non tenentur exprimere causam sententiae suae</b> - Judges are not bound to explain the reason of their judgment.</p>
<p><b>Jura naturae sunt immutabilia</b> - The laws of nature are immutable.</p>
<p><b>Jura publica anteferenda privatis juribus</b> - Public rights are to be preferred to private rights.</p>
<p><b>Juramentum est indivisibile et non est admittendum in parte verum et in parte falsum</b> - An oath is indivisible and it is not to be held partly true and partly false.</p>
<p><b>Jurare est Deum in testem vocare, et est actus divini cultus</b> - To swear is to call God to witness and is an act of divine worship.</p>
<p><b>Jus</b> - A right that is recognised in law.</p>
<p><b>Jus accrescendi praefertur oneribus</b> - The right of survivorship is preferred to incumbrances.</p>
<p><b>Jus ad rem; jus in re</b> - A right to a thing; a right in a thing.</p>
<p><b>Jus dicere, non jus dare</b> - To declare the law, not to make the law.</p>
<p><b>Jus est norma recti; et quicquid est contra normam recti est injuria</b> - The law is a rule of right; and whatever is contrary to a rule of right is an injury.</p>
<p><b>Jus naturale</b> - Natural justice.</p>
<p><b>Jus naturale est quod apud omnes homines eandem habet potentiam</b> - Natural right is that which has the same force among all men.</p>
<p><b>Jus scriptum aut non scriptum</b> - The written law or the unwritten law.</p>
<p><b>Jusjurandum inter alios factum nec nocere nec prodesse debet</b> - An oath made between third parties ought neither to hurt nor profit.</p>
<p><b>Justitia est duplec; severe puniens et vere praeveniens</b> - Justice is two-fold; severely punishing and in reality prohibiting (offences).</p>
<p><b>Justitia firmatur solium</b> - The throne is established by justice.</p>
<p><b>Justitia nemini neganda est</b> - Justice is to be denied to no one.</p>

   </Layout>
  )
}

export default LatQuotes_j
